import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LedgerGroupDetail = _resolveComponent("LedgerGroupDetail")!
  const _component_BasicFormDialog = _resolveComponent("BasicFormDialog")!
  const _component_BasicTablePage = _resolveComponent("BasicTablePage")!

  return (_openBlock(), _createBlock(_component_BasicTablePage, {
    searchInputs: _ctx.searchInputs,
    searchButtons: _ctx.searchButtons,
    tableColumns: _ctx.tableColumns,
    tableData: _ctx.tableData,
    pagination: _ctx.pagination,
    tableRowKey: "uGuid"
  }, {
    dialog: _withCtx(() => [
      _createVNode(_component_LedgerGroupDetail, { ref: "ledgerGroupDetailRef" }, null, 512),
      _createVNode(_component_BasicFormDialog, {
        ref: "groupAddDialogRef",
        title: "新增分组",
        modelValue: _ctx.groupAddDialogShow,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.groupAddDialogShow) = $event)),
        formItems: _ctx.groupAddFormItems,
        footerButtons: _ctx.groupAddButtons,
        formRules: _ctx.groupAddRules,
        width: "700px",
        labelWidth: "100px"
      }, null, 8, ["modelValue", "formItems", "footerButtons", "formRules"])
    ]),
    _: 1
  }, 8, ["searchInputs", "searchButtons", "tableColumns", "tableData", "pagination"]))
}