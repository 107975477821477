
import { defineComponent, onActivated, reactive, ref, toRef } from 'vue'
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
import LedgerGroupDetail from '@/views/ledger/LedgerGroupDetail.vue'
import usePagination from '@/utils/composables/usePagination'
import { Buttons, FormItems, Inputs, TableColumns } from '@/types/BasicComponent'
import { addLedgerGroup, AddLedgerGroupData, getLedgerGroupList, LedgerGroupQueryParams } from '@/api/ledger'
import { ElMessage } from 'element-plus'
import { FormRulesMap } from 'element-plus/lib/components/form/src/form.type'

export default defineComponent({
  components: {
    BasicTablePage,
    BasicFormDialog,
    LedgerGroupDetail
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<LedgerGroupQueryParams>({})
    const tableData = ref<AnyArray>([])

    onActivated(() => {
      getTableData()
    })

    const getTableData = async () => {
      const request = Object.assign({}, searchData, { pageNo: pagination.currentPage, pageSize: pagination.pageSize })
      const resp = await getLedgerGroupList(request)
      const respData = resp.data.data
      tableData.value = respData.records
      pagination.total = respData.total
    }
    pagination.setCallback(getTableData)

    // 新增分组弹窗
    const groupAddDialogRef = ref()
    const groupAddDialogShow = ref(false)
    const groupAddData = reactive<AddLedgerGroupData>({
      groupName: '',
      groupDesc: ''
    })
    const groupAddFormItems = reactive<FormItems>([
      {
        label: '分组名称',
        type: 'input',
        prop: 'group_Name',
        model: toRef(groupAddData, 'groupName'),
        clearable: false,
        fullRow: true,
      }, {
        label: '分组描述',
        type: 'textarea',
        prop: 'group_Desc',
        model: toRef(groupAddData, 'groupDesc'),
        clearable: false,
        fullRow: true,
      }
    ])
    const groupAddButtons = reactive<Buttons>([
      {
        label: '确认',
        onClick: async () => {
          await groupAddDialogRef.value.validate()
          await addLedgerGroup(groupAddData)
          ElMessage.success?.('添加分账分组成功')
          groupAddDialogShow.value = false
          getTableData()
        }
      }, {
        label: '取消',
        type: 'default',
        onClick: () => groupAddDialogShow.value = false
      }
    ])
    // 校验规则
    const groupAddRules: FormRulesMap = {
      group_Name: [
        { required: true, message: '请输入分组名称' }
      ]
    }

    const ledgerGroupDetailRef = ref()
    const searchInputs = reactive<Inputs>([
      {
        label: '规则分组名称',
        type: 'input',
        model: toRef(searchData, 'groupName'),
        clearable: true
      }
    ])
    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        onClick: getTableData
      }, {
        label: '新增分组',
        onClick: () => groupAddDialogShow.value = true
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        type: 'index',
        label: '序号',
        width: '60px',
        align: 'center',
        index: idx => pagination.pageOffset + idx
      }, {
        prop: 'groupName',
        label: '分组名',
        minWidth: '100px',
      }, {
        prop: 'groupDesc',
        label: '分组描述',
        minWidth: '200px'
      }, {
        type: 'button',
        label: '操作',
        align: 'center',
        width: '150px',
        fixed: 'right',
        buttons: [
          {
            label: '编辑',
            onClick: scope => ledgerGroupDetailRef.value.show(scope.row.gid)
          }
        ]
      }
    ])

    return {
      searchInputs,
      pagination,
      searchData,
      searchButtons,
      tableColumns,
      tableData,
      ledgerGroupDetailRef,
      groupAddDialogShow,
      groupAddFormItems,
      groupAddButtons,
      groupAddRules,
      groupAddDialogRef,
      getTableData
    }
  }
})
