import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c85a1ce6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { style: {"color":"#19A97B"} }
const _hoisted_4 = { style: {"width":"100%","display":"flex","justify-content":"center","margin-top":"30px","margin-bottom":"-20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_BasicInputGroup = _resolveComponent("BasicInputGroup")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_BasicFormDialog = _resolveComponent("BasicFormDialog")!

  return (_openBlock(), _createBlock(_component_BasicFormDialog, {
    modelValue: _ctx.modalShow,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalShow) = $event)),
    title: "分组详情",
    footerButtons: _ctx.footerButtons,
    width: "1300px",
    top: "7vh",
    closeOnClickModal: true
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formItemsList, (formItems, idx) => {
          return (_openBlock(), _createBlock(_component_el_card, {
            class: "box-card",
            shadow: "hover",
            key: idx
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", null, [
                  _createTextVNode("分账规则：" + _toDisplayString(_ctx.inputDataList[idx].lname) + " ", 1),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.inputDataList[idx].isDefault? '（默认规则）': ''), 1)
                ]),
                _createVNode(_component_el_space, null, {
                  default: _withCtx(() => [
                    (!_ctx.inputDataList[idx].isDefault)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          type: "text",
                          style: {"color":"#f56c6c"},
                          onClick: ($event: any) => (_ctx.onRemoveLedger(idx))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("删除")
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                ref_for: true,
                ref: _ctx.handleLedgerFormRef(idx),
                model: _ctx.inputDataList[idx],
                rules: _ctx.formRules(idx),
                "label-width": "165px",
                class: "form-container"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BasicInputGroup, {
                    inputs: formItems,
                    render: _ctx.render
                  }, null, 8, ["inputs", "render"])
                ]),
                _: 2
              }, 1032, ["model", "rules"])
            ]),
            _: 2
          }, 1024))
        }), 128)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_button, {
            type: "primary",
            size: "small",
            icon: "el-icon-plus",
            onClick: _ctx.onAddLedger
          }, {
            default: _withCtx(() => [
              _createTextVNode("新增规则")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "footerButtons"]))
}