
import { computed, defineComponent, reactive, ref, toRef } from "vue"
import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
import BasicInputGroup from '@/components/BasicPageLayout/BasicInputGroup.vue'
import { Buttons, FormItem, FormItems } from "@/types/BasicComponent"
import { ElMessage, ElMessageBox } from "element-plus"
import { batchAddLedger, deleteLedger, getLedgerListByGroupId } from '@/api/ledger'
import validate from "@/utils/validate"
import { FormRulesMap } from "element-plus/lib/components/form/src/form.type"

export default defineComponent({
  components: {
    BasicFormDialog,
    BasicInputGroup
  },
  emits: ['on-success'],
  setup(props, { emit }) {
    const modalShow = ref(false)

    // 新增规则时的初始化数据
    const initialData: AnyObject = {
      ltype: 1,
      topup: 1,
      repeat: 2,
      enable: 1,
      lsort: 1,
      contractType: 1,
      ignore: 2,
      forcepay: 1,
      isGetInt: 1
    }

    const groupId = ref(-1)
    const show = async (id: number) => {
      // 重置数据
      inputDataList.length = 0
      groupId.value = id
      const resp = await getLedgerListByGroupId(id)
      const respData = resp.data.data as AnyArray
      respData.forEach(item => {
        const ledgerDataObject = {} as AnyObject
        Object.keys(item).forEach(key => ledgerDataObject[key] = item[key])
        // 如果优先级为99，则这个数据为默认数据，只能修改名称
        if (item.lsort === 99) {
          ledgerDataObject.isDefault = true
        }
        inputDataList.push(ledgerDataObject)
      })
      modalShow.value = true
    }

    const inputDataList = reactive<AnyArray>([])

    // @ts-ignore
    const render = (inputRender: JSX.Element, itemConfig: FormItem) => (
      <el-col span={itemConfig.fullRow? 24: 8}>
        <el-form-item
          prop={itemConfig.prop} 
          size="small"
          v-slots={{
            default: () => <div style={{display: 'flex', justifyContent: 'center'}}>{inputRender}</div>,
            label: () => typeof itemConfig.label === 'function'? itemConfig.label() : itemConfig.label
          }}
        ></el-form-item>
      </el-col>
    )

    // 添加规则
    const onAddLedger = () => {
      inputDataList.push({ ...initialData })
    }
    // 删除规则
    const onRemoveLedger = async (idx: number) => {
      await ElMessageBox.confirm('是否确认删除规则？', '提示')
      if (inputDataList[idx].lid != null) {
        await deleteLedger(inputDataList[idx].lid)
      }
      inputDataList.splice(idx, 1)
      ElMessage.success?.('删除成功')
    }

    const handleLedgerFormRef = (idx: number) => (el: any) => {
      formRefs[idx] = el
    }
    const formRefs = reactive<AnyArray>([])
    const formItemsList = computed(() => {
      const itemList = reactive<FormItems[]>([])
      inputDataList.forEach((inputData, idx) => {
        const itemDetail = reactive<FormItems>([
          {
            label: '规则名',
            type: 'input',
            model: toRef(inputData, 'lname'),
            prop: 'lname',
          }, {
            label: '分账类型',
            type: 'select',
            model: toRef(inputData, 'ltype'),
            prop: 'ltype',
            clearable: false,
            disabled: () => inputData.isDefault,
            options: [
              { value: 1, label: '百分比' },
              { value: 2, label: '固定值' }
            ]
          }, {
            label: '分账数值',
            type: 'input',
            model: toRef(inputData, 'lvalue'),
            prop: 'lvalue',
            disabled: () => inputData.isDefault,
            suffixRender: () => (inputData.ltype === 1? '%': '元') + ' / 方'
          }, {
            label: '规则优先级',
            type: 'input',
            model: toRef(inputData, 'lsort'),
            prop: 'lsort',
            disabled: () => inputData.isDefault
          }, {
            label: '是否提现可选',
            type: 'select',
            model: toRef(inputData, 'topUp'),
            prop: 'topUp',
            clearable: false,
            disabled: () => inputData.isDefault,
            show: false,
            options: [
              { value: 1, label: '可选' },
              { value: 2, label: '不可选' }
            ]
          }, {
            label: '是否可重复',
            type: 'select',
            model: toRef(inputData, 'repeat'),
            prop: 'repeat',
            clearable: false,
            disabled: () => inputData.isDefault,
            show: false,
            options: [
              { value: 1, label: '可重复' },
              { value: 2, label: '不可重复' }
            ]
          }, {
            label: '是否可用',
            type: 'select',
            model: toRef(inputData, 'enable'),
            prop: 'enable',
            clearable: false,
            disabled: () => inputData.isDefault,
            options: [
              { value: 1, label: '可用' },
              { value: 2, label: '不可用' }
            ]
          }, {
            label: '是否可跳过',
            type: 'select',
            model: toRef(inputData, 'ignore'),
            prop: 'ignore',
            clearable: false,
            disabled: () => inputData.isDefault,
            options: [
              { value: 1, label: '可跳过' },
              { value: 2, label: '不可跳过' }
            ]
          }, {
            label: '是否强制支付',
            type: 'select',
            model: toRef(inputData, 'forcepay'),
            prop: 'forcepay',
            clearable: false,
            disabled: () => inputData.isDefault,
            options: [
              { value: 1, label: '是' },
              { value: 0, label: '否' },
            ]
          }, {
            label: '是否取整',
            type: 'select',
            model: toRef(inputData, 'isGetInt'),
            prop: 'isGetInt',
            clearable: false,
            disabled: () => inputData.isDefault,
            options: [
              { value: 1, label: '是' },
              { value: 0, label: '否' }
            ]
          }
        ])
        itemList.push(itemDetail)
      })
      return itemList
    })
    const formRules = (idx: number) => {
      const ruleMap: FormRulesMap = {
        lname: [
          { required: true, message: '请输入规则名' }
        ],
        lsort: [
          { required: true, message: '请输入规则优先级' },
          { validator: (rule, value) => {
            // 如果是默认规则，不校验
            if (inputDataList[idx].isDefault) return true
            return validate.digits(value) && validate.range(value, [1, 98])
          }, message: '请输入1-98之间的整数' },
          { validator: (rule, value) => {
            const lSortSet = new Set()
            for (let i of inputDataList) {
              if (lSortSet.has(i.lsort) && i.lsort === value) {
                return false
              }
              lSortSet.add(i.lsort)
            }
            return true
          }, message: '规则优先级不能相同' }
        ],
        contractType: [
          { required: true, message: '请输入分账合同类型' },
        ],
        ltype: [
          { required: true, message: '请选择分账类型' }
        ],
        lvalue: [
          { required: true, message: '请输入分账数值' },
          { validator: (rules, value) => {
            // 分账类型
            const lType = inputDataList[idx].ltype
            // 百分比
            if (lType === 1) {
              return validate.range(value, [0, 100])
            }
            // 固定值
            return validate.amount(value)
          }, message: '请输入有效的分账数值' }
        ],
        topUp: [
          { required: true, message: '请选择是否提现可选' },
        ],
        repeat: [
          { required: true, message: '请选择是否可重复' },
        ],
        enable: [
          { required: true, message: '请选择是否可用' },
        ],
        ignore: [
          { required: true, message: '请选择是否可跳过' },
        ],
        forcepay: [
          { required: true, message: '请选择是否强制支付' },
        ]
      }
      return ruleMap
    }

    const footerButtons = reactive<Buttons>([
      {
        label: '保存',
        onClick: async () => {
          let validateFail = false
          for (let r of formRefs) {
            if (!r) continue
            try {
              await r.validate()
            } catch (e) {
              validateFail = true
              console.error(e)
            }
          }
          if (validateFail) return
          inputDataList.forEach(item => item.groupid = groupId.value)
          await batchAddLedger(inputDataList)
          ElMessage.success?.('保存成功')
          emit('on-success')
          modalShow.value = false
        }
      }, {
        label: '取消',
        type: 'default',
        onClick: () => modalShow.value = false
      }
    ])

    return {
      show,
      render,
      onAddLedger,
      onRemoveLedger,
      handleLedgerFormRef,
      formItemsList,
      inputDataList,
      footerButtons,
      formRules,
      modalShow
    }
  }
})
